<template>
  <div class="box">
    <div class="content">
      <div class="banner">
        <el-carousel :interval="3000" arrow="always" height="400px">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div class="title">{{item.journalisTitle}}</div>
            <el-image class="img" :src="echoImg(item.bannerUrl)" fit="cover" @click="goDetails('',item.id)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="list">
        <ul class="ul">
          <template  v-for="(item,index) in subjectList" @key="index">
            <li class="li" :key="index">
              <div class="liTop">
                <div class="liTitle">{{item.navigationName}}</div>
                <div class="liMore" @click="more(item.pnavigationName,item.pid,item.pnavigationType,0)">+MORE</div>
              </div>
              <template v-if="item.navigationType == 2">
                <div class="childrenList" v-for="(dates,idx) in item.journalism" :key="idx">
                  <img class="img" :src="echoImg(dates.coverImg)" style="width: 100%" @click="goDetails('',dates.id)"/>
                  <div class="ql-editor" v-html="item.dataHtml" @click="goDetails('',dates.id)"></div>
                </div>
              </template>
              <template v-else>
                <ul class="childrenList">
                  <template  v-for="(dates,idx) in item.journalism" @key="idx">
                    <li @click="goDetails('',dates.id)" v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)" :key="idx">
                      <span class="listLeft"><i class="el-icon-arrow-right"></i>{{dates.journalisTitle}}</span>
                      <span class="listRight">{{toDate(dates.releaseTime, 'yyyy-MM-dd')}}</span>
                    </li>
                  </template>
                </ul>
              </template>
            </li>
          </template>
        </ul>
      </div>
      <div class="threeImg">
        <div class="liTop">
          <div class="liTitle">精彩图库</div>
<!--          <div class="liMore" @click="more()">+MORE</div>-->
        </div>
        <div class="Containt">
          <ul :style="{'left':calleft + 'px', width: widthData + 'px'} " v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
            <li v-for="(item,index) in mapDepotList" :key="index" ref="lis">
              <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('',item.id)" fit="cover"></el-image>
              <div class="imgDiv">{{item.journalisTitle}}</div>
            </li>
            <li v-for="(dates,idx) in mapDepotList" :key="'c_'+idx" ref="lis1">
              <el-image class="img" :src="echoImg(dates.bannerUrl)" @click="goDetails('',dates.id)" fit="cover"></el-image>
              <div class="imgDiv">{{dates.journalisTitle}}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bBox">
        <ul class="bUl">
          <div class="bTitle">友情链接</div>
          <div class="b">
            <li v-for="(item,index) in linksList1" :key="index" @click="openLinks(item.linkUrl)">{{item.linkName}}</li>
          </div>
        </ul>
        <ul class="bUl">
          <div class="bTitle">合作伙伴</div>
          <div class="b">
            <el-image v-for="(item,index) in linksList2" :key="index" class="img" :class="'img'+index" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)"></el-image>
          </div>
        </ul>
        <ul class="bUl">
          <div class="bTitle">联系我们</div>
          <div class="b">
            <AboutUs />
          </div>
        </ul>
        <ul class="bUl">
          <div class="bTitle"></div>
          <img class="lyImg" src="../images/gg1.jpg" style="margin-top: 20px">
          <img class="lyImg" src="../images/gg2.jpg" style="margin-top: 20px">
        </ul>

      </div>
    </div>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getNavigationTwoList,getTextarea } from "@/api/template";
import AboutUs from "./aboutUs";
export default {
  name: "Index",
  components: { AboutUs },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg']
    };
  },
  created(){
    this.init()
    this.move()
  },
  computed: {
    widthData(){
      return 146 * Number(this.mapDepotList.length * 2)
    }
  },
  mounted() {
    let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()
      // this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
      this.getNavigationTwoList()
    },
    getNavigationTwoList(){
      let self = this
      getNavigationTwoList().then(res =>{
        if(res.code == 200){
          res.data.forEach((dates,index) =>{
            dates.witemVo.forEach((item) =>{
              item.pid = dates.id
              item.pnavigationName = dates.navigationName
              item.pnavigationType = dates.navigationType
              item.dataHtml = ''
              if(item.navigationType == 2){
                item.dataHtml = self.getTextareaFun(item.journalism[0].journalisContent,item.id)
              }
              this.subjectList.push(item)
            })
          })
          // this.subjectList = this.subjectList.reverse()
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type,index){
      // let data = {
      //   name: navigationName,
      //   id: id,
      //   type: type,
      //   index:index
      // }
      // window.sessionStorage.setItem('navigationValue', JSON.stringify(data))
      // this.$router.push({params: {name:navigationName,id:id},name:'fifth-list'})
      this.$router.push({params: {name:navigationName,id:id,type: type,index: index},name:'fifth-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/fifth-details')
    },
    getTextareaFun(journalisContent,id){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        this.subjectList.forEach(item=>{
          if(item.id == id){
            item.dataHtml = res.data.dataHtml
          }
        })
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.mapDepotList.length*146) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
  },
};
</script>
<style lang="scss" scoped>
  .content{
    width: 1024px;
    margin: 0 auto;
    .banner{
      width: 100%;
      height: 400px;
      .img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .title{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0,0,0,0.6);
        z-index: 99;
        color: #fff;
        height: 56px;
        line-height: 56px;
        padding: 0 20px;
      }
    }
    .list{
      .ul{
        display: flex;
        flex-flow:row wrap;
        justify-content: space-between;
        .li{
          float: left;
          width: 320px;
          height: 290px;
          //background: #fff;
          //margin-left: 20px;
          margin-top: 20px;
          overflow: hidden;
          .liTop{
            padding: 10px 0 4px 0;
            height: 29px;
            font-size: 24px;
            color: #000;
            border-bottom: 1px solid #ADADAD;
            margin-bottom: 10px;
            .liTitle{
              //width: 80%;
              float: left;
              text-align: left;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              padding-right: 30px;
              border-bottom: 2px solid #0e3786;
              max-width: 70%;
            }
            .liMore{
              float: right;
              text-align: right;
              cursor: pointer;
              color: #f00;
              font-size: 14px;
              margin-top: 5px;
            }
          }
          .childrenList{
            //margin: 0 10px;
            height: 30px;
            line-height: 30px;
            .circular{
              display: inline-block;
              width: 5px;
              height: 5px;
              background: #1e3c5a;
              border-radius: 5px;
              vertical-align: middle;
              margin-right: 10px;
            }
            .liText{
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 30px;
              width: 280px;
              vertical-align: middle;
              cursor: pointer;
            }
            .img{
              width: 100%;
              height: 130px;
            }
            .ql-editor{
              font-size: 12px;
              color: #666;
              line-height: 20px;
              //margin-top: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              cursor: pointer;
              padding: 0;
              height: 100px;
            }
            .el-icon-arrow-right{
              color: #f00;
              font-weight: bold;
            }
            .listLeft,.listRight{
              font-size: 12px;
              color: #666;
              width: 75%;
              float: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              line-height: 24px;
              cursor: pointer;
            }
            .listRight{
              width: 25%;
              float: right;
              text-align: right;
            }
            .benefit{
              font-size: 12px;
              margin-top: 15px;
              cursor: pointer;
              .left{
                width: 28%;
                margin-right: 2%;
              }
              .right{
                width: 70%;
              }
            }
            .active{
              span{
                color: #f00 !important;
              }
            }
          }
        }
      }
      .ul li:last-child{
        margin-right: 0;
      }
    }
    .clear{
      clear: both;
      height: 20px;
    }
    .threeImg {
      position: relative;
      display: inline-block;
      width: 1024px;
      height: 198px;
      margin-top: 20px;
      .liTop{
        padding: 10px 0 4px 0;
        height: 29px;
        font-size: 24px;
        color: #000;
        border-bottom: 1px solid #ADADAD;
        margin-bottom: 10px;
        .liTitle{
          //width: 80%;
          float: left;
          text-align: left;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          padding-right: 30px;
          border-bottom: 2px solid #0e3786;
        }
        .liMore{
          float: right;
          text-align: right;
          cursor: pointer;
          color: #f00;
          font-size: 14px;
          margin-top: 5px;
        }
      }
      .Containt {
        position: relative;
        //padding: 20px 0;
        overflow-y: auto;
        width: 1024px;
        height: 128px;
        overflow: hidden;
        margin: 0 auto;
        ul {
          margin: 0 auto;
          width: 504px;
          position: absolute;
          left: 0px;
          cursor: pointer;
          z-index: 10;
          overflow: hidden;
          li {
            float: left;
            width: 126px;
            margin-left: 20px;
            overflow: hidden;
            text-align: center;
            .img{
              width: 126px;
              height: 108px;
              cursor: pointer;
            }
            .imgDiv{
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    @keyframes myMou {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    .bBox{
      width: 100%;
      height: 218px;
      border: 1px solid #ECEDEE;
      margin-top: 20px;
      background-image: linear-gradient( #DFE8F2, #F6FAFF);
      overflow: hidden;
      .bUl{
        float: left;
        width: 256px;
        .bTitle{
          height: 40px;
          line-height: 40px;
          color: #628fd0;
          text-align: center;
          border-bottom: 1px solid #bfdbf1;
        }
        .lyImg{
          margin-left: 30px;
        }
        .b{
          height: 176px;
          border-right: 1px solid #bfdbf1;
          overflow-y: auto;
          li{
            text-align: center;
            padding-top: 15px;
            cursor: pointer;
          }
          .img{
            float: left;
            width: 109px;
            height: 37px;
            margin-top: 15px;
            vertical-align: middle;
            margin-left: 11px;
            cursor: pointer;
          }
          .aboutUsBox{
            text-align: left;
            color: #667fa1;
            height: 28px;
            line-height: 28px;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .el-carousel__arrow{
    background-color:rgba(31,45,61,.4);
  }
</style>
