<template>
  <div class="aboutUsBox">
    <div class="title">协会名称：广东省户外协会</div>
    <div class="phone">办公电话：020-38025013</div>
    <div class="tel">救援电话：020-38025013</div>
    <div class="qq">QQ：</div>
    <div class="cz">传真：</div>
    <div class="email">邮箱：nanyue2017@163.com</div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  components: {},
  data() {
    return {

    };
  },
  created(){

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.logo{
  width: 1024px;
  margin: 0 auto;
  position: relative;
  padding: 30px 0;
  .logo_top{
    width: 602px;
    height: 119px;
    .img{
      width: 100%;
      height: 100%;
    }
    .auth-login{
      position: absolute;
      top: 20px;
      right: 10px;
      color: #0000ee;
      cursor: pointer;
      li{
        width: 60px;
        float: left;
        a{
          color: #1989fa;
        }
        :hover{
          background: #1e3c5a;
        }
      }
    }
  }
  .loginAdmin{
    position: absolute;
    right: 20px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
  }
  .userBox{
    position: absolute;
    right: 10px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
    li{
      float: left;
      margin: 0 10px;
    }
  }
}
.navbar{
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #506790;
  //border-bottom: 10px #003366 solid;
  .ul{
    width: 1024px;
    display: flex;
    flex-flow:row wrap;
    justify-content: start;
    margin: 0 auto;
    color: #fff;
    li{
      flex: 1;
      position: relative;
      height: 45px;
      line-height: 45px;
      //padding: 0 10px;
      text-align: center;
      span{
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .menu{
        width: 160px;
        height: auto;
        position: absolute;
        top: 45px;
        left: 0px;
        //border: 1px #d9d9d9 solid;
        background-color: #fff;
        display: none;
        z-index: 9;
        //box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;
        p{
          text-align: left;
          padding-left: 20px;
          line-height: 30px;
          color: #5c5c5c;
          cursor: pointer;
          span{
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
      }
    }
    .active{
      background: #0442B9;
      .menu{
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes myMou {
  from { max-height: 0px; }
  to { max-height: 500px; }
}
</style>
